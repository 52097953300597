
const common = {
  state: {
    lang: {
      header: {},
      common: {},
      aboutUs: {},
      contactUs: {}
    },
    screen: 0,
    headerColor: 0,
    pageNav: 0,
    checkGameStatus: 0,
    img:{
      header: [],
      bannerImg: [],
      gamesImg: [],
      companyImg: [],
      contactImg: [],
      accountImg: [],
      rechargeImg: []
    },
    langType: "en",
    webName: "Vision IT System"
  },
  actions: {},
  mutations: {
    "SET_LANG": (state,active)=>{
      state.lang = active;
    },
    "SET_SCREEN": (state,active)=>{
      state.screen = active;
    },
    "SET_HEADERCOLOR": (state,active)=>{
      state.headerColor = active;
    },
    "SET_PAGENAV": (state,active)=>{
      state.pageNav = active;
    },
    "SET_CHECKGAMESTATUS": (state,active)=>{
      state.checkGameStatus = active;
    },
    "SET_IMG": (state,active)=>{
      state.img = active;
    },
    "SET_LANGTYPE": (state,active)=>{
      // state.langType = active;
    },
  }
}
export default common
