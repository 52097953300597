<template>
  <div id="app" :class="[langType]">
    <i-header></i-header>
    <router-view :class="['main',{'screen':screen}]"></router-view>
    <i-footer></i-footer>
    
  </div>
</template>

<script>
import iHeader from './components/Header/header.vue'
import iFooter from "@/components/Footer/index.vue"
import { isPC } from "@/utils/common"
export default {
  components: { iHeader, iFooter },
  name: "App",
  data (){
    return {
    }
  },
  
  computed: {
    ...Vuex.mapGetters([
      "langType",
      "screen"
    ]),
  },
  mounted () {
    this.setScreen();
    window.addEventListener("resize",(e,w)=>{
      this.setScreen()
    });
  },
  methods:{
    setScreen(){
      
      let screenNum = 0;
      // alert(document.documentElement.clientWidth)
      if(document.documentElement.clientWidth<980){
        document.getElementsByTagName("html")[0].style.fontSize = "90px";
        screenNum = 1;
      }
      if(!isPC()){
        screenNum = 1;
      }
      this.$store.commit("SET_SCREEN",screenNum);
    }
  }
  
};
</script>

<style lang="stylus">
@import './stylus/common.styl';

#app {
  color: $CFtMain;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: $Cf;
  font-family: 'mainEnI';
}
#app.ar{
  font-family: "arI";
  .ar{
    direction: rtl;
  }
}
#app.en{
  .langEnI{
    font-family: "enI";
  }
  .langEnB{
    font-family: "enB";
  }
  .langEnR{
    font-family: "mainEnI";
  }
   
}
</style>
<style lang="stylus" scoped>
.headerPlace{
  font-size: 0;
}
.haveNotch{
  height: .44rem;
}
.main{
  min-height: calc(100vh - 1.65rem);  
  &.screen{
    min-height: calc(100vh - 2.8rem);  
  }
}
</style>

